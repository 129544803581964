import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import ArrowIcon from "../components/ArrowIcon";
import { useState, useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
const ModalCard = ({ Members, individualMember, setSingleModal, setVisible, visible }) => {
    let ref = useRef();
    let respRef = useRef();
    const handleNextSlider = (slider) => {
        let clikedEl = Members.findIndex((el, i) => el === individualMember);
        if (slider == "next") {
            let nextIndex = (clikedEl + 1) % Members.length;
            setSingleModal(Members[nextIndex]);
        } else {
            let nextIndex = (clikedEl - 1) % Members.length;
            nextIndex = nextIndex === -1 ? Members.length - 1 : nextIndex;
            setSingleModal(Members[nextIndex]);
        }
    };
    const handleClose = () => {
        setVisible(false);
    };
    useEffect(() => {
        const checkOutsideClick = (e) => {
            if (
                ref.current &&
                !ref.current.contains(e.target)
                // !respRef.current.contains(e.target)
            ) {
                setVisible(false);
            } else if (
                respRef.current &&
                !respRef.current.contains(e.target)``
                // !ref.current.contains(e.target)
            ) {
                setVisible(false);
            }
        };
        document.addEventListener("click", checkOutsideClick);
        // const checkOutsideClickResp = (el) => {
        //     if (respRef.current && !respRef.current.contains(el.target)) {
        //         setVisible(false);
        //     }
        // };
        // document.addEventListener("click", checkOutsideClickResp);
        return () => {
            document.removeEventListener("click", checkOutsideClick);
            // document.removeEventListener("click", checkOutsideClickResp);
        };
    }, [visible]);
    return (
        <>
            <div className="leader-modal">
                <div className="leader-modal__content" ref={ref}>
                    <div onClick={handleClose}>
                        <StaticImage
                            src="../images/close.svg"
                            class="close-icon"
                            alt="close-icon"
                        />
                    </div>
                    <div className="leader-modal__content-grid">
                        <div>
                            <GatsbyImage
                                image={individualMember?.colorLogo?.gatsbyImageData}
                                alt={individualMember?.colorLogo?.title}
                                className="leader-avatar"
                                loading="lazy"
                            />
                            <Link className="text-decor-none" to={individualMember.linkedinUrl}>
                                <p className="load-more-btn text-p4 text-fw-medium">Linkedin</p>
                            </Link>
                        </div>
                        <div>
                            <h3 className="text-h3 text-clr-primary text-fw-medium">
                                {individualMember?.name}
                            </h3>
                            <p className="text-p4 gap-05x text-clr-primary text-fw-regular">
                                {individualMember?.designation}
                            </p>
                            <p className="text-p5 text-clr-secondary text-fw-medium">
                                {individualMember?.caption?.caption}
                            </p>
                        </div>
                    </div>
                    <div className="leader-modal__content-arrow">
                        <div
                            className="arrow-prev"
                            onClick={(previous) => handleNextSlider("previous")}
                        >
                            <ArrowIcon direction="left" theme={"blue"} />
                        </div>
                        <div className="arrow-next" onClick={(next) => handleNextSlider("next")}>
                            <ArrowIcon direction="right" theme={"blue"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalCard;
