import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ArrowIcon from "./ArrowIcon";
import { useState, useRef, useEffect } from "react";
import ModalCard from "./ModalCard";
import MobileModalCard from "./MobileModalCard";
const Leaders = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const [mobileVisible, setMobileVisible] = useState(false);
    const [members, setMembers] = useState(data.filter((e) => e.founder));
    const [singleModal, setSingleModal] = useState({});
    const handleModal = (element) => {
        let singleMember = data.find((el) => el.name === element);
        setSingleModal(singleMember);
        setVisible(true);
    };
    const handleMobileModal = (element) => {
        let singleMember = data.find((el) => el.name === element);
        setSingleModal(singleMember);
        setMobileVisible(true);
    };

    const headsList = data.sort((a, b) => a.order - b.order).filter((el) => el.founder);

    return (
        <>
            <h2 className="text-h1 gap-2x leader-header text-fw-medium text-clr-primary ">
                Meet the leaders
            </h2>
            {/* <p className="text-p1 text-fw-regular text-clr-secondary">
                Our leadership team brings decades of industry experience and business innovation.
            </p> */}
            <div className="leaders__grid">
                {headsList.map((leader, index) => (
                    <div className="leaders__grid-item" key={index}>
                        <GatsbyImage
                            image={leader.colorLogo?.gatsbyImageData}
                            alt={leader.colorLogo?.title}
                            className="leader-image"
                            loading="lazy"
                        />
                        <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                            {leader.name}
                        </p>
                        <p className=" text-p4 text-clr-secondary text-fw-medium">
                            {leader.designation}
                        </p>
                        {/* <p className="leader-division text-p4 text-clr-secondary text-fw-medium">
                                {leader?.divisionName}
                            </p> */}
                        <div className="arrow" onClick={(e) => handleModal(leader.name, index)}>
                            <ArrowIcon direction="right" theme={"blue"} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="mobile-leaders__grid">
                {data
                    .filter((e) => e.founder)
                    .map((leader, index) => (
                        <div className="mobile-leaders__grid-item" key={index}>
                            <GatsbyImage
                                image={leader.colorLogo?.gatsbyImageData}
                                alt={leader.colorLogo?.title}
                                className="leader-image"
                                loading="lazy"
                            />
                            <p className="leader-name text-p1 text-clr-primary text-fw-medium">
                                {leader.name}
                            </p>
                            <p className=" text-p4 text-clr-secondary text-fw-medium">
                                {leader.designation}
                            </p>
                            {/* <p className="leader-division text-p4 text-clr-secondary text-fw-medium">
                                {leader?.divisionName}
                            </p> */}
                            <div
                                className="arrow"
                                onClick={(e) => handleMobileModal(leader.name, index)}
                            >
                                <ArrowIcon direction="right" theme={"blue"} />
                            </div>
                        </div>
                    ))}
            </div>
            {visible && (
                <ModalCard
                    Members={headsList}
                    setSingleModal={setSingleModal}
                    individualMember={singleModal}
                    setVisible={setVisible}
                    visible={visible}
                    onClose={() => setVisible(false)}
                />
            )}
            {mobileVisible && (
                <MobileModalCard
                    Members={headsList}
                    setSingleModal={setSingleModal}
                    individualMember={singleModal}
                    setMobileVisible={setMobileVisible}
                    mobileVisible={mobileVisible}
                    onClose={() => setMobileVisible(false)}
                />
            )}
        </>
    );
};

export default Leaders;
