import React from "react";
import ArrowIcon from "../components/ArrowIcon";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const timeLineData = [
    {
        year: "2006",
        milestones: [
            {
                name: "New Division: Railsfactory",
                icon: null,
            },
        ],
    },
    {
        year: "2009",
        milestones: [
            {
                name: "100+ Team Members",
                icon: null,
            },
        ],
    },
    {
        year: "2012",
        milestones: [
            {
                name: "100 Clients and 1M revenue",
                icon: null,
            },
        ],
    },
    {
        year: "2013",
        milestones: [
            {
                name: "New Sunnyvale Office & Operations in the US",
                icon: null,
            },
        ],
    },
    {
        year: "2014",
        milestones: [
            {
                name: "Launched IBM Maximo Services",
                icon: null,
            },
        ],
    },
    {
        year: "2015",
        milestones: [
            {
                name: "New Division: Tarka Labs",
                icon: null,
            },
            {
                name: "New Division: Engineering",
                icon: null,
            },
            {
                name: "New Division: Datakulture",
                icon: null,
            },
        ],
    },
    {
        year: "2016",
        milestones: [
            {
                name: "500+ Clients",
                icon: null,
            },
        ],
    },
    {
        year: "2017",
        milestones: [
            {
                name: "EAM360: 1st Mobile App Launch",
                icon: null,
            },
            {
                name: "New Office & Operations in Bangalore, India",
                icon: null,
            },
        ],
    },
    {
        year: "2018",
        milestones: [
            {
                name: "New Division: ECM",
                icon: null,
            },
            {
                name: "New Division: Coderapper",
                icon: null,
            },
        ],
    },
    {
        year: "2019",
        milestones: [
            {
                name: "New Office & Operations in Fredericton, Canada",
                icon: null,
            },
        ],
    },
    {
        year: "2020",
        milestones: [
            {
                name: "Strategic Partnerships with Tableau & Salesforce",
                icon: null,
            },
        ],
    },
    {
        year: "2021",
        milestones: [
            {
                name: "New Division: Salesforce",
                icon: null,
            },
        ],
    },
    {
        year: "2022",
        milestones: [
            {
                name: "500+ Strong Sedinites and Growing ",
                icon: null,
            },
            {
                name: "New Office & Operations in Melbourne, Australia",
                icon: null,
            },
        ],
    },
    {
        year: "2023",
        milestones: [
            {
                name: "New Office & Operations in Singapore",
                icon: null,
            },
        ],
    },
];

const Timeline = () => {
    return (
        <div className="sedin-timeline">
            <div className="sedin-timeline__container">
                <div className="sedin-timeline__nav">
                    <span className="sedin-timeline__nav-prev">
                        <ArrowIcon direction="left" />
                    </span>
                    <span className="sedin-timeline__nav-next">
                        <ArrowIcon direction="right" />
                    </span>
                </div>
                <div className="sedin-timeline__stage">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={30}
                        slidesPerView={"auto"}
                        freeMode={{
                            enabled: true,
                            sticky: false,
                        }}
                        navigation={{
                            nextEl: ".sedin-timeline__nav-next",
                            prevEl: ".sedin-timeline__nav-prev",
                        }}
                        breakpoints={{
                            1024: {
                                freeMode: {
                                    enabled: false,
                                    sticky: true,
                                },
                            },
                        }}
                    >
                        {timeLineData.map((t, i) => (
                            <SwiperSlide key={`${t.year}_${i}`}>
                                <div className="timeline-item">
                                    <div className="timeline-item__year">{t.year}</div>
                                    <div className="timeline-item__milestones">
                                        {t.milestones.map((m, i) => (
                                            <div
                                                key={`${t.year}_milestone_${i}`}
                                                className="milestone"
                                            >
                                                <span className="milestone__pointer"></span>
                                                <div className="milestone__name">{m.name}</div>
                                                {m.icon && (
                                                    <img
                                                        className="milestone__icon"
                                                        src={m.icon}
                                                        alt={m.name}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default Timeline;
