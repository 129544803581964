import * as React from "react";
import Layout from "../../components/Layout";
import { useStaticQuery, graphql } from "gatsby";
import Seo from "../../components/seo";
import Timeline from "../../components/Timeline";
import NavigateBlock from "../../components/NavigateBlock";
import ogIMage from "../../images/og.png";
import { StaticImage } from "gatsby-plugin-image";
import RFLogo from "../../images/divisions/RF-new-logo.svg";
import EAM360Logo from "../../images/divisions/eam360.svg";
import TarkaLabLogo from "../../images/divisions/tarkalabs.svg";
import EngineeringLogo from "../../images/divisions/engineering.svg";
import ECMLogo from "../../images/divisions/ecm.svg";
import CodeRapperLogo from "../../images/divisions/Coderapper_New_Logo.svg";
import DatakultureLogo from "../../images/divisions/datakulture.svg";
import SalesforceLogo from "../../images/divisions/salesforce.svg";
import InViewLogoAnimationController from "../../components/InViewLogoAnimationController";
import Vimeo from "@u-wave/react-vimeo";
import Leaders from "../../components/Leaders";

const AboutPage = ({ location }) => {
    const foundersListWithDivisions = useStaticQuery(graphql`
        {
            allContentfulMembers {
                nodes {
                    id
                    name
                    avatar {
                        gatsbyImageData(quality: 100, width: 176, placeholder: BLURRED)
                        title
                    }
                    caption {
                        caption
                    }
                    designation
                    founder
                    linkedinUrl
                    order
                    colorLogo {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        title
                    }
                }
            }
        }
    `);

    return (
        <Layout>
            <Seo
                title="Sedin | IT Consulting Solutions | Software Services Company"
                image={`https://sedintechnologies.com${ogIMage}/`}
                url={location.href}
                description={
                    "Sedin is an Indian multinational information technology company that provides global business consulting, digital, IT solutions and outsourcing services. "
                }
            />
            <section className="about-hero">
                <div className="container container--first flex flex-ai-c">
                    <div className="hero-text">
                        <div className="section-tag gap-3x">Our company</div>
                        <h1 className="text-h2 text-fw-medium text-clr-primary gap-3x">
                            Since 2006, we’ve helped businesses untangle challenges, infuse
                            innovation, and move from strategy to execution faster.
                        </h1>
                    </div>
                </div>
            </section>
            <section className="numbers">
                <div className="container container--py-v2 numbers__container">
                    <div className="numbers__container-pane">
                        <h2 className="text-h2 text-fw-medium text-clr-primary gap-05x">
                            Sedin by the numbers.
                        </h2>
                        <p className="text-p2 text-fw-regular text-clr-secondary gap-3x">
                            Vital statistics that keep us ticking.
                        </p>
                    </div>
                    <div className="numbers__container-pane">
                        <div className="numbers__row">
                            <div className="numbers__row-col">
                                <div className="text-p3 text-fw-regular text-clr-secondary">
                                    Employees
                                </div>
                                <div className="number__count text-clr-primary text-fw-regular">
                                    500+
                                </div>
                            </div>
                            <div className="numbers__row-col">
                                <div className="text-p3 text-fw-regular text-clr-secondary">
                                    Offices
                                </div>
                                <div className="number__count text-clr-primary text-fw-regular">
                                    6
                                </div>
                            </div>
                        </div>
                        <div className="numbers__row">
                            <div className="numbers__row-col">
                                <div className="text-p3 text-fw-regular text-clr-secondary">
                                    Customers
                                </div>
                                <div className="number__count text-clr-primary text-fw-regular">
                                    500+
                                </div>
                            </div>
                            <div className="numbers__row-col">
                                <div className="text-p3 text-fw-regular text-clr-secondary">
                                    Projects
                                </div>
                                <div className="number__count text-clr-primary text-fw-regular">
                                    1000+
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="division-structure">
                <div className="container container--mid-v3">
                    <div className="division-structure__grid">
                        <div className="division-structure__grid-title">
                            <h2 className="text-h2 text-clr-primary text-fw-medium">
                                Where big tech meets bigger thoughts
                            </h2>
                        </div>
                        <div className="division-structure__grid-content">
                            <p className="text-p3 text-clr-secondary text-fw-regular">
                                We are Sedin. From full-stack development to enterprise-specific
                                solutions, data analytics to engineering— we cater advanced and
                                intuitive solutions to the needs of budding startups and large-scale
                                enterprises.
                            </p>
                            <p className="text-p3 text-clr-secondary text-fw-regular">
                                Conceived from the dream of two driven technologists in 2006, we are
                                now a global pioneer of IT services and solutions with eight unique
                                and industry-disrupting technology divisions comprising 500+
                                Sedinites across the world.
                            </p>
                            <p className="text-p3 text-clr-secondary text-fw-regular">
                                As a seasoned leader with the startup DNA in our veins, we’re
                                constantly expanding, exploring and establishing innovative ways to
                                positively impact our clients and drive their success.
                            </p>
                        </div>
                    </div>
                    <div className="division-structure__logo-grid">
                        <div className="division-structure__logo-grid__item">
                            <img
                                // src="../../images/divisions/RF-new-logo.svg"
                                src={RFLogo}
                                placeholder="blurred"
                                alt="railsfactory"
                                loading="lazy"
                            />
                        </div>
                        <div className="division-structure__logo-grid__item">
                            <img
                                src={EAM360Logo}
                                placeholder="blurred"
                                alt="eam360"
                                loading="lazy"
                            />
                        </div>
                        <div className="division-structure__logo-grid__item">
                            <img
                                src={TarkaLabLogo}
                                placeholder="blurred"
                                alt="tarkalbs"
                                loading="lazy"
                            />
                        </div>
                        <div className="division-structure__logo-grid__item">
                            <img
                                src={EngineeringLogo}
                                placeholder="blurred"
                                alt="engineering"
                                loading="lazy"
                            />
                        </div>

                        <div className="division-structure__logo-grid__item">
                            <img src={ECMLogo} placeholder="blurred" alt="ecm" loading="lazy" />
                        </div>
                        <div className="division-structure__logo-grid__item">
                            <img
                                // src="../../images/divisions/coderapper.svg"
                                src={CodeRapperLogo}
                                placeholder="blurred"
                                alt="coderapper"
                                loading="lazy"
                            />
                        </div>
                        <div className="division-structure__logo-grid__item">
                            <img
                                src={DatakultureLogo}
                                placeholder="blurred"
                                alt="datakulture"
                                loading="lazy"
                            />
                        </div>

                        <div className="division-structure__logo-grid__item">
                            <img
                                src={SalesforceLogo}
                                placeholder="blurred"
                                alt="salesforce"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="video-section">
                <div className="container container--mid-v2">
                    <InViewLogoAnimationController animationName={"play"}>
                        <Vimeo
                            height="684"
                            video={"https://vimeo.com/722166760"}
                            responsive={true}
                        />
                    </InViewLogoAnimationController>
                </div>
            </section>
            <section className="timeline">
                <div className="container container--mid-v2">
                    <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                        Our growth story.
                    </h2>
                    <div className="timeline-wrp">
                        <Timeline />
                    </div>
                </div>
            </section>
            {/* <section className="home-clients">
                <div className="container container--mid-v2">
                    <Testimonials />
                </div>
            </section> */}

            <section className="sedin-leader">
                <div className="container container--mid-v2">
                    <Leaders data={foundersListWithDivisions.allContentfulMembers.nodes}></Leaders>
                </div>
            </section>

            <section className="a-about-solutions container--last">
                <div className="container">
                    <NavigateBlock
                        header="Businesses across the globe resonate with our work already."
                        buttonText=" Explore our services"
                    />
                </div>
            </section>
        </Layout>
    );
};

export default AboutPage;
